import options from '@/config/app.options';
import i18n from '@/lang';
import { authService, tokenService } from '@/services';
import { env, HttpClient, Logger } from '@/utils';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { v4 } from 'uuid';

const queue = {};

/**
 * @class Api Class is a fancy es6 wrapper class for axios.
 */
export class Api extends HttpClient {
  /**
   * Creates an instance of api.
   * @param {AxiosRequestConfig} conf
   */
  constructor(
    conf: AxiosRequestConfig & {
      withoutInterceptors?: boolean;
    } = {}
  ) {
    super({
      ...conf,
      baseURL: env('API_URL'),
    });

    this.registerRequestHeaders();
    this.registerQueryParams();

    if (conf.withoutInterceptors) {
      return;
    }

    this.registerTooManyRequestsInterceptor();
    this.registerAuthTypeInterceptor();
    this.registerNotAuthorizedInterceptor();
    this.registerPassportInterceptor();
  }

  protected registerRequestHeaders = (): void => {
    this.interceptors.request.use((config) => {
      return {
        ...config,
        headers: {
          ...options.defaultHttpHeaders,
          ...config.headers,
          Authorization: tokenService.getBearerToken(),
          'Accept-Language':
            i18n.global.locale.value || options.i18nConfig.defaultLocale,
        },
      };
    });
  };

  protected registerQueryParams = (): void => {
    this.interceptors.request.use((config) => {
      const url = new URL(document.location.toString());
      const urlParams = new URLSearchParams(url.search);
      const paramAllowList = ['_querylog'];
      const params = {
        ...config.params,
      };

      paramAllowList.forEach((param) => {
        if (!urlParams.has(param)) {
          return;
        }

        params[param] = urlParams.get(param);
      });

      return {
        ...config,
        params,
      };
    });
  };

  protected registerTooManyRequestsInterceptor = (): void => {
    this.interceptors.response.use(
      (response) => response,
      async (
        error: AxiosError<LaravelErrorResponse> & { isHandled?: boolean }
      ) => {
        const originalRequest = error.config;

        if (error.request.status !== 429) {
          return Promise.reject(error);
        }

        const id = v4();
        const retryAfter = error.response?.headers['retry-after'];
        const retryAfterSeconds = parseInt(retryAfter || '1', 10);

        const key = `${originalRequest.url}.${originalRequest.method}`;
        queue[key] ? queue[key].push(id) : (queue[key] = [id]);

        return new Promise((resolve, reject) => {
          setTimeout(async () => {
            const index = queue[key].findIndex((i) => i === id);
            if (index + 1 === queue[key].length) {
              resolve(await this.request(originalRequest));
              return;
            }

            error.isHandled = true;
            reject(error);
          }, retryAfterSeconds * 1000);
        });
      }
    );
  };

  protected registerNotAuthorizedInterceptor = (): void => {
    this.interceptors.response.use(
      (response) => response,
      async (error: AxiosError<LaravelErrorResponse>) => {
        const originalRequest = error.config;

        if (
          error.request.status !== 401 &&
          error.response &&
          error.response.data.exception === 'AuthorizationException'
        ) {
          Logger.info('[Api] not 401 but authorization exception');

          this.invalidateToken();
          return Promise.reject(error);
        }

        if (error.request.status !== 401) {
          Logger.info('[Api] different than 401');

          return Promise.reject(error);
        }

        if (originalRequest.url.includes('/oauth/token')) {
          Logger.info('[Api] Includes token url');

          // Refresh token has failed.
          this.invalidateToken();
          return Promise.reject(error);
        }

        try {
          if (!tokenService.getTokenResponse()?.refresh_token) {
            authService.login();
            Logger.info('[Api] Refresh token not found');

            return Promise.reject(error);
          }

          Logger.info('[Api] Refreshing token');
          await authService.refreshToken();
          Logger.info('[Api] Token refreshed');

          Logger.logObject(
            '[Api] request',
            originalRequest as unknown as Record<string, unknown>
          );
          return await new Api({ withoutInterceptors: true }).request(
            originalRequest
          );
        } catch (err) {
          Logger.info('[Api] Error found');

          if ((err as AxiosError).request?.status === 401) {
            Logger.info('[Api] Error 401 therefore login');
            authService.login();
          }

          Logger.info('[Api] request rejected');
          return Promise.reject(err);
        }
      }
    );
  };

  protected invalidateToken = (): void => {
    tokenService.removeAll();
  };

  protected registerPassportInterceptor = (): void => {
    if (env('AUTH') !== 'passport') {
      return;
    }

    Logger.info('[Api] passport interceptor registered');

    this.interceptors.response.use(
      (response) => response,
      async (error: AxiosError<LaravelErrorResponse>) => {
        const originalRequest = error.config;
        if (
          error.request.status === 401 &&
          error.response &&
          error.response.data &&
          error.response.data.message &&
          error.response.data.message.includes('Unauthenticated') &&
          !window.location.href.includes('/auth?code')
        ) {
          if (!tokenService.getRefreshToken()) {
            return this.clearTokensAndRedirectToLogin();
          }

          try {
            await authService.refreshToken();
          } catch (e) {
            return this.clearTokensAndRedirectToLogin();
          }

          return this.request(originalRequest);
        }
        return Promise.reject(error);
      }
    );
  };

  protected registerAuthTypeInterceptor = (): void => {
    this.interceptors.response.use(
      (response) => response,
      async (error: AxiosError<LaravelErrorResponse>) => {
        if (
          error.request.status === 401 &&
          error.response &&
          error.response.data &&
          error.response.data.error === 'auth_type'
        ) {
          Logger.info('[Api] auth-type error');

          this.invalidateToken();
          window.location.href = 'error/auth-type';
          return Promise.reject(error);
        }

        if (!tokenService.getAuthType()) {
          Logger.info('[Api] Auth type not found');

          authService.login();
          return Promise.reject(error);
        }

        if (
          tokenService.getAuthType() &&
          tokenService.getAuthType() !== options.defaultHttpHeaders['Auth-Type']
        ) {
          Logger.info('[Api] Auth type has changed');

          this.invalidateToken();
          return Promise.reject(error);
        }

        return Promise.reject(error);
      }
    );
  };

  protected clearTokensAndRedirectToLogin = (): void => {
    this.invalidateToken();
    authService.login();
  };
}
