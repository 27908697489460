import { SidebarLayout } from '@/components/layouts';
import appOptions from '@/config/app.options';
import { hasProjectsMembershipGuard } from '@/router/guards/hasProjectsMembershipGuard';
import { RouteHelper } from '@/router/RouteHelper';
import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/translation/:id',
    name: 'translation',
    component: () => import('../../pages/translation/[id].vue'),
    props: ({ params }) => ({
      id: RouteHelper.convertParam(params.id, 'number'),
    }),
    beforeEnter: [
      hasProjectsMembershipGuard([
        appOptions.Projects.ArticleDescriptions,
        appOptions.Projects.Product,
        appOptions.Projects.Category,
        appOptions.Projects.LandingPage,
      ]),
    ],
    meta: {
      hidden: true,
      layout: SidebarLayout,
      roles: appOptions.adminRoleNames,
    },
  },
];

export default routes;
