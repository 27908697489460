<script lang="ts" setup>
import BaseSkeleton from '@/components/core/BaseSkeleton/BaseSkeleton.vue';
import SolidCheckCircle from '@/components/icons/heroicons/solid/SolidCheckCircle.vue';
import SolidInformationCircle from '@/components/icons/heroicons/solid/SolidInformationCircle.vue';
import SolidX from '@/components/icons/heroicons/solid/SolidX.vue';
import SolidXCircle from '@/components/icons/heroicons/solid/SolidXCircle.vue';
import { computed, provide, toRef } from 'vue';

const props = withDefaults(
  defineProps<{
    isLoading?: boolean;
    leftBar?: boolean;
    dismissable?: boolean;
    type?: 'success' | 'error' | 'warning' | 'info';
  }>(),
  {
    isLoading: false,
    withLeftBar: false,
    type: 'warning',
    dismissable: false,
  }
);

const emits = defineEmits<{
  (e: 'side-button'): void;
  (e: 'dismiss'): void;
}>();

function onDismiss(): void {
  emits('dismiss');
}

function onSideButton(): void {
  emits('side-button');
}
const textColor = computed(() => {
  switch (props.type) {
    case 'error':
      return 'text-red-800 dark:text-red-400';
    case 'info':
      return 'text-blue-800 dark:text-blue-400';
    case 'success':
      return 'text-green-800 dark:text-green-400';
    case 'warning':
      return 'text-yellow-800 dark:text-yellow-400';
    default:
      return '';
  }
});

const sideButtonColor = computed(() => {
  switch (props.type) {
    case 'error':
      return 'text-red-700 hover:text-red-600';
    case 'info':
      return 'text-blue-700 hover:text-blue-600';
    case 'success':
      return 'text-green-700 hover:text-green-600';
    case 'warning':
      return 'text-yellow-700 hover:text-yellow-600';
    default:
      return '';
  }
});

const borderColor = computed(() => {
  switch (props.type) {
    case 'error':
      return 'border-red-300 dark:border-red-800';
    case 'info':
      return 'border-blue-300 dark:border-blue-800';
    case 'success':
      return 'border-green-300 dark:border-green-800';
    case 'warning':
      return 'border-yellow-300 dark:border-yellow-800';
    default:
      return '';
  }
});

const backgroundColor = computed(() => {
  switch (props.type) {
    case 'error':
      return 'bg-red-50 dark:bg-gray-800';
    case 'info':
      return 'bg-blue-50 dark:bg-gray-800';
    case 'success':
      return 'bg-green-50 dark:bg-gray-800';
    case 'warning':
      return 'bg-yellow-50 dark:bg-gray-800';
    default:
      return '';
  }
});

const dismissButtonColor = computed(() => {
  switch (props.type) {
    case 'error':
      return 'bg-red-50 text-red-500 hover:bg-red-100 focus:ring-offset-red-50 focus:ring-red-600';
    case 'info':
      return 'bg-blue-50 text-blue-500 hover:bg-blue-100 focus:ring-offset-blue-50 focus:ring-blue-600';
    case 'success':
      return 'bg-green-50 text-green-500 hover:bg-green-100 focus:ring-offset-green-50 focus:ring-green-600';
    case 'warning':
      return 'bg-yellow-50 text-yellow-500 hover:bg-yellow-100 focus:ring-offset-yellow-50 focus:ring-yellow-600';
    default:
      return '';
  }
});

provide('type', toRef(props, 'type'));
</script>

<template>
  <div class="p-4" :class="['border', borderColor, backgroundColor, textColor]">
    <div class="flex">
      <div
        class="ml-3"
        :class="{
          'flex-1 md:flex md:jusify-between': $slots.sideButton && !dismissable,
        }"
      >
        <div class="flex items-center">
          <BaseSkeleton :is-loading="isLoading" width="w-5" height="w-5">
            <SolidCheckCircle
              v-if="type === 'success'"
              class="flex-shrink-0 w-5 h-5 me-2"
            />
            <SolidInformationCircle
              v-if="type === 'info'"
              class="flex-shrink-0 w-5 h-5 me-2"
            />
            <SolidXCircle
              v-if="type === 'error'"
              class="flex-shrink-0 w-5 h-5 me-2"
            />
            <SolidInformationCircle
              v-if="type === 'warning'"
              class="flex-shrink-0 w-5 h-5 me-2"
            />
          </BaseSkeleton>

          <h3 v-if="$slots.title" class="text-lg font-medium">
            <BaseSkeleton :is-loading="isLoading" width="w-48">
              <slot name="title"></slot>
            </BaseSkeleton>
          </h3>
        </div>

        <div class="text-sm" :class="[$slots.title ? 'mt-2' : '']">
          <p class="text-sm">
            <BaseSkeleton :is-loading="isLoading" width="w-96">
              <slot />
            </BaseSkeleton>
          </p>
        </div>

        <div v-if="$slots.buttons" class="mt-4">
          <div class="-my-1.5 flex" :class="!isLoading ? '-mx-2 ' : ''">
            <BaseSkeleton :is-loading="isLoading" width="w-72">
              <slot name="buttons"></slot>
            </BaseSkeleton>
          </div>
        </div>
      </div>
      <div v-if="!isLoading && $slots.sideButton && !dismissable">
        <p class="mt-3 text-sm md:mt-0 md:ml-6">
          <a
            href="#"
            class="whitespace-nowrap font-medium"
            :class="sideButtonColor"
            @click="onSideButton"
          >
            <slot name="sideButton" />
            <span aria-hidden="true">&rarr;</span></a
          >
        </p>
      </div>
    </div>
    <div
      v-if="!isLoading && !$slots.sideButton && dismissable"
      class="ml-auto pl-3"
      @click="onDismiss"
    >
      <div class="-mx-1.5 -my-1.5">
        <button
          type="button"
          class="inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2"
          :class="dismissButtonColor"
        >
          <SolidX class="h-5 w-5" />
        </button>
      </div>
    </div>
  </div>
</template>
