import { InboxFull, ListIcon } from '@/components/icons/flowbite';
import { BrandPagekit } from '@/components/icons/tablericons';
import { SidebarLayout } from '@/components/layouts';
import appOptions from '@/config/app.options';
import { hasProjectsMembershipGuard } from '@/router/guards/hasProjectsMembershipGuard';
import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/cms',
    name: 'cms.index',
    redirect: () => {
      return { name: 'cms.articles' };
    },
    beforeEnter: [
      hasProjectsMembershipGuard([
        appOptions.Projects.Product,
        appOptions.Projects.Category,
        appOptions.Projects.LandingPage,
      ]),
    ],
    meta: {
      layout: SidebarLayout,
      icon: BrandPagekit,
      roles: appOptions.adminRoleNames,
    },
    children: [
      {
        path: '/cms/articles',
        name: 'cms.articles',
        component: () => import('~/pages/cms/articles.vue'),
        beforeEnter: [
          hasProjectsMembershipGuard([
            appOptions.Projects.Product,
            appOptions.Projects.Category,
            appOptions.Projects.LandingPage,
          ]),
        ],
        meta: {
          layout: SidebarLayout,
          icon: InboxFull,
          roles: appOptions.adminRoleNames,
        },
      },
      {
        path: '/cms/tasks',
        name: 'cms.tasks',
        component: () => import('~/pages/cms/tasks.vue'),
        beforeEnter: [
          hasProjectsMembershipGuard([
            appOptions.Projects.Product,
            appOptions.Projects.Category,
            appOptions.Projects.LandingPage,
          ]),
        ],
        meta: {
          layout: SidebarLayout,
          icon: ListIcon,
          roles: appOptions.adminRoleNames,
        },
      },
    ],
  },
];

export default routes;
