import { OutlineTranslate } from '@/components/icons/heroicons';
import { SidebarLayout } from '@/components/layouts';
import appOptions from '@/config/app.options';
import { hasProjectsMembershipGuard } from '@/router/guards/hasProjectsMembershipGuard';
import { RouteHelper } from '@/router/RouteHelper';
import { TranslationViewService } from '@/services';
import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/article/text/:id',
    name: 'articleText.translation',
    component: () => import('@/modules/articles/views/TranslationView.vue'),
    props: ({ params }) => ({
      id: RouteHelper.convertParam(params.id, 'number'),
      service: TranslationViewService,
    }),
    beforeEnter: [
      hasProjectsMembershipGuard(appOptions.Projects.ArticleDescriptions),
    ],
    meta: {
      layout: SidebarLayout,
      icon: OutlineTranslate,
      hidden: true,
      breadcrumbs: {
        parent: 'articleText.tasks',
      },
      query: {
        key: 'tab',
        value: null,
      },
    },
  },
  {
    path: '/article/text/:action/:id',
    name: 'articleText.action',
    component: () =>
      import('@/modules/articles/views/TranslationActionView.vue'),
    props: ({ params }) => ({
      id: RouteHelper.convertParam(params.id, 'number'),
      action: params.action,
    }),
    beforeEnter: [
      hasProjectsMembershipGuard(appOptions.Projects.ArticleDescriptions),
    ],
    meta: {
      layout: SidebarLayout,
      hidden: true,
      disableBreadcrumbs: true,
    },
  },
];

export default routes;
