import { useMembership } from '@/composables';
import { useUserStore } from '@/store';

export function hasProjectsMembershipGuard(
  projectIds: number[] | number,
  adminRequired = false
) {
  const projects = Array.isArray(projectIds) ? projectIds : [projectIds];

  return (to, from, next) => {
    const userStore = useUserStore();
    const userMembership = useMembership(userStore.user);

    if (adminRequired && projects.every((id) => userMembership.isAdmin(id))) {
      return next();
    }

    if (projects.every((id) => userMembership.hasAccess(id))) {
      return next();
    }

    return next('/403');
  };
}
